import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
const windowGlobal = typeof window !== 'undefined' && window
const isWindow = typeof window !== 'undefined'

const defaultLocale = ''

const LocaleContext = React.createContext(defaultLocale)

const LocaleContextProvider = ({ children }) => {
const locales = ["en-AU", "en-NZ"]
const [path, setPath] = useState('')
useEffect(() => {
  const pathname = windowGlobal.location.pathname
  setPath(pathname)
}, [])
const pathLocale = path.split('/')
const loadLocale = pathLocale[1]
const initialLocale = locales.indexOf(loadLocale)

  const [locale, setLocale] = useState(initialLocale >= 0 ? loadLocale : 'en-AU' )

	return (
		<LocaleContext.Provider value={[locale, setLocale]}>{children}</LocaleContext.Provider>
	)
}

export { LocaleContext, LocaleContextProvider }
