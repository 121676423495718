import React, { createContext, useReducer } from 'react'
const initialState = {
	open: false,
	focus: false,
	play: false,
	query: ``,
}
const reducer = (state, action) => {
	switch (action.type) {
		case 'TOGGLE:':
			return {
				...state,
				...action.toggle,
			}
		case 'setOpen:':
			return {
				...state,
				open: action.open,
			}
		case 'setFocus':
			return {
				...state,
				focus: action.focus,
			}
		case 'setPlay':
			return {
				...state,
				focus: action.play,
			}
		case 'setQuery':
			return {
				...state,
				query: action.query,
			}
		case 'setState':
			return {
				...state,
				...action.state,
			}
		default:
			return state
	}
}
export const SearchContext = createContext([{}, () => {}])
export const SearchProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	return (
		<SearchContext.Provider value={[state, dispatch]}>
			{children}
		</SearchContext.Provider>
	)
}
