import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
const windowGlobal = typeof window !== 'undefined' && window
const isBrowser = typeof window !== 'undefined'
/**
 * useCartWithLocalStorage - bind localStorage with state
 * get cart {Object} from `localStorage`
 * @returns {Promise}
 */
export const useCartWithLocalStorage = (key, defaultValue) => {
	const localStorage = (isBrowser && window.localStorage.getItem(key)) || ''
	const localCart = (localStorage && JSON.parse(localStorage)) || ''
	const [cart, setCart] = useState(localCart || defaultValue)
	useEffect(() => {
		windowGlobal.localStorage.setItem(key, JSON.stringify(cart))
	}, [cart, key])
	return [cart, setCart]
}
const defaultCart = {
	lineItems: [],
	paymentMethod: { id: '', billing_details: {} },
	id: '',
	isPickUp: false,
}
/** CartContext - Provider and Consumer */
const CartContext = React.createContext([{}, () => {}])
/**
 * CartProvider - Wrapping the layout in context API goodness
 * @param {Object} props
 * @param {Node} props.children
 */
const CartProvider = ({ children }) => {
	/** set Line Items */
	const [cart, setCart] = useCartWithLocalStorage('cart', defaultCart)
	return (
		<CartContext.Provider value={[cart, setCart]}>
			{children}
		</CartContext.Provider>
	)
}
CartProvider.propTypes = {
	children: PropTypes.node.isRequired,
}
export { CartContext, CartProvider }
