import React, { useState, useEffect } from 'react'

const Faq = [{}]

const products = []

const FaqContext = React.createContext(Faq)

const FaqContextProvider = ({ children }) => {

  const [faqCat, setFaqCat] = useState(products)


  return (
    <FaqContext.Provider value={[faqCat, setFaqCat]}>{children}</FaqContext.Provider>
  )
}

export { FaqContext, FaqContextProvider }
