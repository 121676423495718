import React, { useState, useEffect } from 'react'
const windowGlobal = typeof window !== 'undefined' && window
const isWindow = typeof window !== 'undefined'

const TableProducts = [{}]

const products = []

const ProductContext = React.createContext(TableProducts)

const ProductContextProvider = ({ children }) => {

  const [table, setTable] = useState(products)
  useEffect(() => {
    const tableData = windowGlobal.sessionStorage.getItem('table')
    if ( tableData === null ) {
      windowGlobal.sessionStorage.setItem('table', JSON.stringify(table))
      windowGlobal.sessionStorage.setItem('residential', JSON.stringify(table))
      windowGlobal.sessionStorage.setItem('commercial', JSON.stringify(table))
      windowGlobal.sessionStorage.setItem('mineral-swim', JSON.stringify(table))
      windowGlobal.sessionStorage.setItem('ozone-swim', JSON.stringify(table))
    } else {
      setTable(JSON.parse(tableData))
    }
  }, [])
  
	return (
		<ProductContext.Provider value={[table, setTable]}>{children}</ProductContext.Provider>
	)
}

export { ProductContext, ProductContextProvider }
