// tailwind.config.js
const defaultTheme = require('tailwindcss/defaultTheme')
const selectorParser = require('postcss-selector-parser')
const rgba = require('hex-to-rgba')
const colors = require('@tailwindcss/ui/colors')
const customFormsPlugin = require('@tailwindcss/custom-forms')

const spacing = {
	px: '1px',
	'0': '0',
	'0.5': '0.125rem',
	'1': '0.25rem',
	'1.5': '0.375rem',
	'2': '0.5rem',
	'2.5': '0.625rem',
	'3': '0.75rem',
	'3.5': '0.875rem',
	'4': '1rem',
	'5': '1.25rem',
	'6': '1.5rem',
	'7': '1.75rem',
	'8': '2rem',
	'9': '2.25rem',
	'10': '2.5rem',
	'11': '2.75rem',
	'12': '3rem',
	'13': '3.25rem',
	'14': '3.5rem',
	'15': '3.75rem',
	'16': '4rem',
	'20': '5rem',
	'24': '6rem',
	'28': '7rem',
	'32': '8rem',
	'36': '9rem',
	'40': '10rem',
	'48': '12rem',
	'56': '14rem',
	'60': '15rem',
	'64': '16rem',
	'72': '18rem',
	'80': '20rem',
	'96': '24rem',
	'1/2': '50%',
	'1/3': '33.333333%',
	'2/3': '66.666667%',
	'1/4': '25%',
	'2/4': '50%',
	'3/4': '75%',
	'1/5': '20%',
	'2/5': '40%',
	'3/5': '60%',
	'4/5': '80%',
	'1/6': '16.666667%',
	'2/6': '33.333333%',
	'3/6': '50%',
	'4/6': '66.666667%',
	'5/6': '83.333333%',
	'1/12': '8.333333%',
	'2/12': '16.666667%',
	'3/12': '25%',
	'4/12': '33.333333%',
	'5/12': '41.666667%',
	'6/12': '50%',
	'7/12': '58.333333%',
	'8/12': '66.666667%',
	'9/12': '75%',
	'10/12': '83.333333%',
	'11/12': '91.666667%',
	full: '100%',
}

const boxShadow = {
	...defaultTheme.boxShadow,
	solid: '0 0 0 2px currentColor',
	outline: `0 0 0 3px ${rgba(colors.blue[400], 0.45)}`,
	'outline-gray': `0 0 0 3px ${rgba(colors.gray[400], 0.45)}`,
	'outline-blue': `0 0 0 3px ${rgba(colors.blue[300], 0.45)}`,
	'outline-teal': `0 0 0 3px ${rgba(colors.teal[300], 0.45)}`,
	'outline-green': `0 0 0 3px ${rgba(colors.green[300], 0.45)}`,
	'outline-yellow': `0 0 0 3px ${rgba(colors.yellow[300], 0.45)}`,
	'outline-orange': `0 0 0 3px ${rgba(colors.orange[300], 0.45)}`,
	'outline-red': `0 0 0 3px ${rgba(colors.red[300], 0.45)}`,
	'outline-pink': `0 0 0 3px ${rgba(colors.pink[300], 0.45)}`,
	'outline-purple': `0 0 0 3px ${rgba(colors.purple[300], 0.45)}`,
	'outline-indigo': `0 0 0 3px ${rgba(colors.indigo[300], 0.45)}`,
}

const maxWidth = (theme, { breakpoints }) => ({
	none: 'none',
	xs: '20rem',
	sm: '24rem',
	md: '28rem',
	lg: '32rem',
	xl: '36rem',
	'2xl': '42rem',
	'3xl': '48rem',
	'4xl': '56rem',
	'5xl': '64rem',
	'6xl': '72rem',
	'7xl': '80rem',
	full: '100%',
	...breakpoints(theme('screens')),
})

const maxHeight = theme => ({
	screen: '100vh',
	...theme('spacing'),
})

const inset = theme => ({
	auto: 'auto',
	...theme('spacing'),
})

const customForms = theme => ({
	default: {
		input: {
			appearance: 'none',
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			borderRadius: defaultTheme.borderRadius.md,
			paddingTop: spacing[2],
			paddingRight: spacing[3],
			paddingBottom: spacing[2],
			paddingLeft: spacing[3],
			fontSize: defaultTheme.fontSize.base,
			lineHeight: defaultTheme.lineHeight.normal,
			'&::placeholder': {
				color: colors.gray[400],
			},
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
		},
		textarea: {
			appearance: 'none',
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			borderRadius: defaultTheme.borderRadius.md,
			paddingTop: spacing[2],
			paddingRight: spacing[3],
			paddingBottom: spacing[2],
			paddingLeft: spacing[3],
			fontSize: defaultTheme.fontSize.base,
			lineHeight: defaultTheme.lineHeight.normal,
			'&::placeholder': {
				color: colors.gray[400],
				opacity: '1',
			},
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
		},
		multiselect: {
			appearance: 'none',
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			borderRadius: defaultTheme.borderRadius.md,
			paddingTop: spacing[2],
			paddingRight: spacing[3],
			paddingBottom: spacing[2],
			paddingLeft: spacing[3],
			fontSize: defaultTheme.fontSize.base,
			lineHeight: defaultTheme.lineHeight.normal,
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
		},
		select: {
			appearance: 'none',
			colorAdjust: 'exact',
			'&::-ms-expand': {
				border: 'none', // The select padding is causing some whitespace around the chevron which looks weird with a border
				'@media not print': {
					display: 'none',
				},
			},
			backgroundRepeat: 'no-repeat',
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			borderRadius: defaultTheme.borderRadius.md,
			paddingTop: spacing[2],
			paddingRight: spacing[10],
			paddingBottom: spacing[2],
			paddingLeft: spacing[3],
			fontSize: defaultTheme.fontSize.base,
			lineHeight: defaultTheme.lineHeight.normal,
			backgroundPosition: `right ${spacing[2]} center`,
			backgroundSize: `1.5em 1.5em`,
			iconColor: colors.gray[400],
			icon: iconColor =>
				`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none"><path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke="${iconColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
		},
		checkbox: {
			appearance: 'none',
			colorAdjust: 'exact',
			'&::-ms-check': {
				'@media not print': {
					color: 'transparent', // Hide the check
					background: 'inherit',
					borderColor: 'inherit',
					borderRadius: 'inherit',
				},
			},
			display: 'inline-block',
			verticalAlign: 'middle',
			backgroundOrigin: 'border-box',
			userSelect: 'none',
			flexShrink: 0,
			height: spacing[4],
			width: spacing[4],
			color: colors.blue[500],
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			borderRadius: defaultTheme.borderRadius.default,
			iconColor: colors.white,
			icon: iconColor =>
				`<svg viewBox="0 0 16 16" fill="${iconColor}" xmlns="http://www.w3.org/2000/svg"><path d="M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z"/></svg>`,
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
			'&:checked': {
				borderColor: 'transparent',
				backgroundColor: 'currentColor',
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			},
			'&:checked:focus': {
				borderColor: 'transparent',
			},
		},
		radio: {
			appearance: 'none',
			colorAdjust: 'exact',
			'&::-ms-check': {
				'@media not print': {
					color: 'transparent', // Hide the check
					background: 'inherit',
					borderColor: 'inherit',
					borderRadius: 'inherit',
				},
			},
			display: 'inline-block',
			verticalAlign: 'middle',
			backgroundOrigin: 'border-box',
			userSelect: 'none',
			flexShrink: 0,
			borderRadius: '100%',
			height: spacing[4],
			width: spacing[4],
			color: colors.blue[500],
			backgroundColor: colors.white,
			borderColor: colors.gray[300],
			borderWidth: defaultTheme.borderWidth.default,
			iconColor: colors.white,
			icon: iconColor =>
				`<svg viewBox="0 0 16 16" fill="${iconColor}" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="3"/></svg>`,
			'&:focus': {
				outline: 'none',
				boxShadow: boxShadow['outline-blue'],
				borderColor: colors.blue[300],
			},
			'&:checked': {
				borderColor: 'transparent',
				backgroundColor: 'currentColor',
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			},
			'&:checked:focus': {
				borderColor: 'transparent',
			},
		},
	},
})

module.exports = {
	theme: {
		container: {
			center: true,
		},
		aspectRatio: {
			1: '1',
		},
		extend: {
			width: {
				'95': '95%',
			},
			boxShadow: {
				...defaultTheme.boxShadow,
				solid: '0 0 0 2px currentColor',
				outline: `0 0 0 3px ${rgba(colors.blue[400], 0.45)}`,
				'outline-gray': `0 0 0 3px ${rgba(colors.gray[400], 0.45)}`,
				'outline-blue': `0 0 0 3px ${rgba(colors.blue[300], 0.45)}`,
				'outline-teal': `0 0 0 3px ${rgba(colors.teal[300], 0.45)}`,
				'outline-green': `0 0 0 3px ${rgba(colors.green[300], 0.45)}`,
				'outline-yellow': `0 0 0 3px ${rgba(colors.yellow[300], 0.45)}`,
				'outline-orange': `0 0 0 3px ${rgba(colors.orange[300], 0.45)}`,
				'outline-red': `0 0 0 3px ${rgba(colors.red[300], 0.45)}`,
				'outline-pink': `0 0 0 3px ${rgba(colors.pink[300], 0.45)}`,
				'outline-purple': `0 0 0 3px ${rgba(colors.purple[300], 0.45)}`,
				'outline-indigo': `0 0 0 3px ${rgba(colors.indigo[300], 0.45)}`,
			},
			spacing,
			height:{
				'70vh': '70vh',
			},
			fontSize: {
				...defaultTheme.fontSize,
				'2xs': '.625rem',
				xs: '.75rem',
				sm: '.875rem',
				tiny: '.875rem',
				base: '1rem',
				lg: '1.125rem',
				xl: '1.25rem',
				'2xl': '1.5rem',
				'3xl': '1.875rem',
				'4xl': '2.25rem',
				'5xl': '3rem',
				'6xl': '4rem',
				'7xl': '5rem',
				'8xl': '8rem',
				'9xl': '9rem',
				'10xl': '10rem',
				'11xl': '11rem',
			},
			fontFamily: {
				sans: ['Inter', ...defaultTheme.fontFamily.sans],
				display: ['Inter', ...defaultTheme.fontFamily.sans],
				line: ['magistral', ...defaultTheme.fontFamily.sans],
			},
			screens: {
				xs: '375px',
				mxs: '525px',
				sm: '640px',
				md: '768px',
				sb: '900px',
				lg: '1024px',
				xl: '1280px',
				xxl: '1400px',
				xxxl: '1700px',
			},
			colors: {
				inherit: 'inherit',
				transparent: 'transparent',
				white: '#ffffff',
				black: '#000000',
				gray: {
					'50': '#f9fafb',
					'100': '#f4f5f7',
					'200': '#e5e7eb',
					'300': '#d2d6dc',
					'400': '#9fa6b2',
					'500': '#6b7280',
					'600': '#4b5563',
					'700': '#374151',
					'800': '#252f3f',
					'900': '#161e2e',
				},
				yellow: {
					'50': '#FAF7EE',
					'100': '#F4E2B9',
					'200': '#F5D68F',
					'300': '#F8CA65',
					'400': '#FAC041',
					'500': '#FDB515',
					'600': '#EEA709',
					'700': '#D8980C',
					'800': '#A17209',
					'900': '#7D5A0B',
				},
				blue: {
					'50': '#f0fcff',
					'100': '#ebf7fb',
					'200': '#c1e7f3',
					'300': '#a9deec',
					'400': '#83d2e4',
					'500': '#58c8df',
					'600': '#58aabc',
					'700': '#508699',
					'800': '#426f7c',
					'900': '#36525b',
				},
				red: {
					'50': '#fdf2f2',
					'100': '#fde8e8',
					'200': '#fbd5d5',
					'300': '#f8b4b4',
					'400': '#f98080',
					'500': '#f05252',
					'600': '#e02424',
					'700': '#c81e1e',
					'800': '#9b1c1c',
					'900': '#771d1d',
				},
				green: {
					'50': '#f3faf7',
					'100': '#def7ec',
					'200': '#bcf0da',
					'300': '#84e1bc',
					'400': '#31c48d',
					'500': '#0e9f6e',
					'600': '#057a55',
					'700': '#046c4e',
					'800': '#03543f',
					'900': '#014737',
				},
				teal: {
					'50': '#edfafa',
					'100': '#d5f5f6',
					'200': '#afecef',
					'300': '#7edce2',
					'400': '#16bdca',
					'500': '#0694a2',
					'600': '#047481',
					'700': '#036672',
					'800': '#05505c',
					'900': '#014451',
				},
				'dolphin-gray': '#353E47',
				'dolphin-light': '#7A848E',
				'dolphin-light-gray': '#ACB7C4',
				'mineral-teal': '#5B89A9',
				brown: '#654321',

				'dark-grayish-blue': '#8094a2',
				'greyish-blue': '#58809f',
				'light-grayish-navy': '#80889A',
				'light-gray': '#ededed',
				'dark-gray': '#e1e1e1',
				sky: '#58808d',
				'light-flesh': '#fce9bb',
			},
			maxWidth,
			maxHeight,
			inset,
			customForms: customForms,
		},
		variants: {
			aspectRatio: ['responsive', 'hover'],
			backgroundColor: [
				'responsive',
				'group-hover',
				'group-focus',
				'hover',
				'focus',
				'active',
			],
			borderColor: [
				'responsive',
				'group-hover',
				'group-focus',
				'hover',
				'focus',
			],
			textColor: [
				'responsive',
				'group-hover',
				'group-focus',
				'hover',
				'focus-within',
				'focus',
				'active',
			],
			textDecoration: [
				'responsive',
				'group-hover',
				'group-focus',
				'hover',
				'focus',
			],
			zIndex: ['responsive', 'focus-within', 'focus'],
		},
		corePlugins: {},
		plugins: [
			require('@tailwindcss/aspect-ratio'),
			({
				addUtilities,
				addComponents,
				addVariant,
				theme,
				e,
				prefix,
				variants,
				postcss,
			}) => {
				customFormsPlugin({ addUtilities, addComponents, theme, postcss })
				addVariant('group-focus', ({ modifySelectors, separator }) => {
					return modifySelectors(({ selector }) => {
						return selectorParser(selectors => {
							selectors.walkClasses(sel => {
								sel.value = `group-focus${separator}${sel.value}`
								sel.parent.insertBefore(
									sel,
									selectorParser().astSync(prefix('.group:focus '))
								)
							})
						}).processSync(selector)
					})
				})
			},
		],
	},
}
