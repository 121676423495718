import React from 'react'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
// import { OktaProvider } from './src/context/okta'
import { ThemeProvider } from './src/context/theme'
import { SearchProvider } from './src/context/search'
import { CartProvider } from './src/context/CartContext'
import { ProductContextProvider } from './src/context/addProductContext'
import { FaqContextProvider } from './src/context/faqContext'
import { LocaleContextProvider } from './src/context/localeContext'
const isWindow = typeof window !== 'undefined'
const localStorage = isWindow && window.localStorage
const oktaTokenStorage =
	(isWindow && localStorage.getItem('okta-token-storage')) || ''
const tokenStorage = oktaTokenStorage && JSON.parse(oktaTokenStorage)
const token =
	tokenStorage &&
	tokenStorage.accessToken &&
	tokenStorage.accessToken.accessToken
const client = new ApolloClient({
	uri: '/.netlify/functions/graphql',
	request: operation => {
		operation.setContext({
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		})
	},
	fetch,
})
export const wrapRootElement = ({ element }) => (
	<LocaleContextProvider>
		<ThemeProvider>
			<SearchProvider>
				<CartProvider>
					<ProductContextProvider>
						<FaqContextProvider>
							<ApolloProvider client={client}>{element}</ApolloProvider>
						</FaqContextProvider>
					</ProductContextProvider>
				</CartProvider>
			</SearchProvider>
		</ThemeProvider>
	</LocaleContextProvider>
)
export const onServiceWorkerUpdateReady = () => window.location.reload(true)
