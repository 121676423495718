import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import theme from '../components/utils/theme'
const initialTheme = {
	theme,
	locale: process.env.GATSBY_LOCALE || 'en-AU',
	windowPos: { x: 0, y: 0 },
	menu: {
		open: false,
		sub: false,
		focus: false,
		play: false,
		sticky: false,
		pos: { x: 0, y: 0 },
	},
	cart: {
		open: false,
		focus: false,
		play: false,
	},
	signIO: {
		open: false,
		focus: false,
		play: false,
	},
}
const themeReducer = (state, action) => {
	switch (action.type) {
		case 'SET_STATE':
			return {
				...state,
				...action.state,
			}
		case 'SET_POS':
			return {
				...state,
				windowPos: action.pos,
			}
		case 'OPEN_MENU':
			return {
				...state,
				menu: {
					...state.menu,
					open: true,
					play: true,
				},
			}
		case 'CLOSE_MENU':
			return {
				...state,
				menu: {
					...state.menu,
					open: false,
					play: false,
				},
			}
		case 'OPEN_SUBMENU':
			return {
				...state,
				menu: {
					...state.menu,
					sub: true,
				},
			}
		case 'CLOSE_SUBMENU':
			return {
				...state,
				menu: {
					...state.menu,
					sub: false,
				},
			}
		case 'OPEN_CART':
			return {
				...state,
				cart: {
					...state.cart,
					open: true,
					play: true,
				},
			}
		case 'CLOSE_CART':
			return {
				...state,
				cart: {
					...state.cart,
					open: false,
					play: false,
				},
			}
		case 'OPEN_SIGNIO':
			return {
				...state,
				signIO: {
					...state.signIO,
					open: true,
					play: true,
				},
			}
		case 'CLOSE_SIGNIO':
			return {
				...state,
				signIO: {
					...state.signIO,
					open: false,
					play: false,
				},
			}
		default:
			break
	}
}
export const ThemeContext = createContext([{}, () => {}])
/**
 * ThemeProvider - Wrapping the layout in context API goodness
 * @param {Object} props
 * @param {Node} props.children
 */
export const ThemeProvider = ({ children }) => {
	const [state, dispatch] = useReducer(themeReducer, initialTheme)
	return (
		<ThemeContext.Provider value={[state, dispatch]}>
			{children}
		</ThemeContext.Provider>
	)
}
ThemeProvider.propTypes = {
	children: PropTypes.node.isRequired,
}
